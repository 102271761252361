import React from 'react';
import Navbars from './Layout/Navbars';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from '../../../hooks/useAuth';
import axiosClient from "../../../api/axios";
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';

const VotersDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    categories: []
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get("/getElectionType"); // Replace with your API endpoint
        setData(response.data);
        setTimeout(() => {
          setLoading(false); // Set loading to false when data is available
        }, 1000);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // If the response status is 401 (Unauthenticated), redirect the user to the login page
          // Replace '/login' with the actual path of your login page
          window.location.href = '/';
          setLoading(false);
        } 
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const navigateToEdit = (record) => {
    navigate("/positions", { state: { record: record } });
  };
  const LoaderOverlay = () => {
    return (
      <div className="loader-overlay">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  };

  const formatStartDate = (startDate) => {
    if (!startDate) {
      return 'None';
    }

    const dateObject = new Date(startDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return dateObject.toLocaleDateString('en-US', options);
  };
    return (
        <Container fluid>
 <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
    {/* Vertical Navbar */}
    <Navbars/>
    {/* Main content */}
    <div className="h-screen flex-grow-1 overflow-y-lg-auto">
      {/* Header */}
      <header className="bg-surface-primary border-bottom pt-6">
        <div className="container-fluid">
          <div className="mb-npx">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                {/* Title */}
                <h1 className="h2 mb-0 ls-tight">Voters Management System</h1>
              </div>
              {/* Actions */}
              
            </div>
            {/* Nav */}
            
          </div>
        </div>
      </header>
      {/* Main */}
      <main className="py-6 bg-surface-secondary">
        <div className="container-fluid">
          {/* Card stats */}
          <div className="row g-6 mb-6">
          {loading ? (
          <LoaderOverlay />
      ) : (
        <>
          <Row>
            {data.categories.map((category) => (
              <Col key={category.id} md={4} className="d-flex justify-content-center align-items-center mt-5">
                
                <Card style={{ width: '20rem', margin: '10px' }}>
                  <Card.Body>
                    <Card.Title>{category.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Department: {category.department ? category.department.name : 'All Students'}
                    </Card.Subtitle>
                    <Card.Title>
                      {category.isGeneral ? 'General Election' : 'Departmental Elections'}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 mt-3 text-muted">
                      Start Date: {formatStartDate(category.start_time)}
                    </Card.Subtitle>
                    <Button onClick={(e) => navigateToEdit(category)} variant="primary">View Details</Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
          </div>
 
        </div>
      </main>
    </div>
  </div>

      </Container>
    )
}

export default VotersDashboard