import { Card, Button, Row, Col, Spinner } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import axiosClient from "../../../api/axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import {API_URL} from '../../../api/axios'

const CandidateCard = ({ candidate, allLoading, setAllLoading, position_id  }) => {
  const [notfound, setNotFound] = useState(true);
  const [loading, setLoading] = useState(false); // State for button-specific loading
  const navigate = useNavigate(); // Use the useNavigate hook

  const goBack = () => {
    navigate(-1); // Use the navigate function to navigate back
  };
  useEffect(() => {
    const checkVote = async () => {
      try {
        await axiosClient.get(`/checkVote/${candidate.id}/${position_id}`)
          .then((response) => {
            if(response.data.status === false){
              setNotFound(false);
            }else{
              setNotFound(true);
            }
          })
      } catch (error) {
        if (error.response.status === 403) {
          setNotFound(true);
        }
      }
    };

    checkVote();
  }, [candidate.id]);

  const handleVoteClick = async () => {
    setLoading(true); // Set loading to true when the button is clicked
    setAllLoading(true); 
    const payload = {
      'candidate_id': candidate.id,
      'position_id': position_id
    };

    try {
      await axiosClient.post(`vote`, payload)
        .then(() => {
          setNotFound(true);
          // Display success toast
          toast.success('You have successfully Voted', {
            position: 'top-right',
            autoClose: 2000, // Auto close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });   
        setTimeout(() => {
          goBack(); // Set loading to false when data is available
        }, 1000);
        })
    } catch (error) {
      if (error.response.status === 403) {
        setNotFound(true);
        toast.error('Error Casting Votes', {
          position: 'top-right',
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
      });
      }
    } 
  };
  const loadingAll = () => {
    // Logic to check if all buttons are loading
    // You can use any appropriate condition here based on your requirements
    return allLoading;
  };

  return (
    <Col key={candidate.id} md={4} className="d-flex justify-content-center align-items-center mt-5">
      {/* 'mt-5' adds top margin */}
      <ToastContainer />
      <Card style={{ width: '20rem', margin: '10px' }}>
      <Card.Img variant="top" src={`${API_URL}${candidate.image_url}`} />

        <Card.Body>
          <Card.Title>{candidate.name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            Election: {candidate.category.name}
          </Card.Subtitle>
          <Card.Title className="mb-2 text-muted">
            Position: {candidate.position.name}
          </Card.Title>
          {/* <Button variant="primary" onClick={handleVoteClick} disabled={notfound || loading}>
            {loading ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              'Vote Now'
            )}
          </Button> */}
          <Button variant="primary" onClick={handleVoteClick} disabled={notfound || loading || allLoading}>
            {loading || allLoading ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              'Vote Now'
            )}
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};

const CandidateList = ({ candidates, position_id }) => {
  const navigate = useNavigate(); // Use the useNavigate hook
  const [allLoading, setAllLoading] = useState(false); // State to track if all buttons are loadi
  const goBack = () => {
    navigate(-1); // Use the navigate function to navigate back
  };
  return (
    <>
    
    <Row className='text-center'>
      {candidates.map((candidate) => (
        <CandidateCard key={candidate.id} candidate={candidate} allLoading={allLoading} setAllLoading={setAllLoading}position_id={position_id} />
      ))}
    </Row>
    <div className="d-flex justify-content-center align-items-center mt-5">
            <button className="btn btn-danger" onClick={goBack}>Go Back</button>
          </div>
    </>
    
  );
};

export default CandidateList;
