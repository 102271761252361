import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axiosClient from "../../api/axios";
import "./login.css";
import LoaderOverlay from "../../utils/loader";
import { axiosverify } from "../../api/verificationAxios";

const rsendLink = "/email/resend";

const ResendVerificationLink = () => {
  const [userData, setUserData] = useState({
    email: "",
    voter_number: ""
  });

  const [errors, setErrors] = useState({});
  const [userExists, setUserExists] = useState("");

  const [validationResult, setValidationResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name,  value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
    // Validate the field and update the errors state
    validateField(name, value);
    // Prevent the default paste behavior
    if (name === 'voter_number') {
      try {
        setLoadingCheck(true)
        // Make an API request to check if the registration number already exists
        const response = await axiosClient.get(`/checkMatric/${value.trim()}`);
        if (response.data.status === true) {
          setValidationResult(true);
           // Update email value in userData
          setUserData((prevData) => ({ ...prevData, email: response.data.email }));
          setUserExists("");
          setLoadingCheck(false)
        } else {
          setValidationResult(false);
          setUserExists(response.data.error);
          setLoadingCheck(false)
        }
      } catch (error) {
        // const response = error.response;
        setValidationResult(false);
        // setUserExists(response.data.error);
        setLoadingCheck(false)
      }
    }
  };
  
  const handleBlur = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
    // Validate the field and update the errors state
    validateField(name, value);
  
    if (name === 'voter_number') {
      try {
        setLoadingCheck(true);
        // Make an API request to check if the registration number already exists
        const response = await axiosClient.get(`/checkMatric/${value.trim()}`);
        if (response.data.status === true) {
           // Update email value in userData
          setUserData((prevData) => ({ ...prevData, email: response.data.email }));
          setValidationResult(true);
          setUserExists("");
          setLoadingCheck(false);
        } else {
          setValidationResult(false);
          setUserExists(response.data.error);
          setLoadingCheck(false);
        }
      } catch (error) {
        // Handle error
        setValidationResult(false);
        setLoadingCheck(false);
      }
    }
  };

  const validateField = (name, value) => {
    let error = "";

    // Add your validation logic here
    switch (name) {
      case "email":
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        error = !emailRegex.test(value) ? "Enter a valid email address" : "";
        break;
      // Add validation for other fields as needed
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };


  
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    location.state && location.state.from ? location.state.from.pathname : "/";

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const payload = {
            'email': userData.email
        }
          const response = await axiosverify.get(rsendLink, payload);
          if (response.data.status === true) {
              // Registration successful
              toast.success('Verification link has been sent to your email', {
                  position: "top-right",
                  autoClose: 3500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              });
              setLoading(false);
              localStorage.removeItem('Verifytoken')
              setTimeout(() => {
                  navigate(from, { replace: true });
              }, 2000);
          } else {
              // Registration failed due to validation errors
              const { data } = response.data;
              if (data) {
                  Object.entries(data).forEach(([key, value]) => {
                      toast.error(`${value[0]}`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                      });
                  });
              } else {
                  toast.error('An error occurred', {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                  });
              }
              setLoading(false);
          }
      } catch (err) {
          const { data } = err.response.data;
          if (data) {
              Object.entries(data).forEach(([key, value]) => {
                  toast.error(`${value[0]}`, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                  });
              });
          } else {
              toast.error('An error occurred', {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              });
          }
          setLoading(false);
      }
    
      };

  useEffect(() => {
  }, [userExists]);
 
  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="py-5 text-center">
        <div className="text-center">
                  <img
                    src="../logo.jpg"
                    className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                    width="200px"
                    alt="profile"
                  />
                </div>
        </div>
        <div className="row">
          <div className="col-md-8 order-md-1 offset-md-2 cardbody-color p-lg-5">
            <h4 className="mb-3 text-center">Resend Verification Link</h4>
            <form
              className="needs-validation"
              noValidate
              onSubmit={handleSubmit}
            >
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Reg/Matric Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="voter_number"
                    placeholder
                    defaultValue
                    autoComplete="off"
                    onBlur={handleBlur} // Trigger handleBlur function when input loses focus
                    value={userData.voter_number}
                    onChange={handleChange}
                    required
                  />
               {loadingCheck ? <span>Loading...</span> :
               <span style={{ color: 'red' }}>{userExists}</span>
               }   
                </div>

              <div className="row">
                
                <div className="col-md-12 mb-3">
                  <label htmlFor="email">
                    Email <span className="text-muted">(Compulsory)</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    placeholder="you@example.com"
                    required
                    readOnly
                  />
                  <div className="invalid-feedback">
                    {errors.email && <span>{errors.email}</span>}
                  </div>
                </div>
              </div>
              <hr className="mb-4" />
              {loading ? (
          <LoaderOverlay />
      ) :  <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
                disabled={!validationResult}
              >
                Resend Link
              </button>
}
            </form>
          </div>
        </div>
        <footer className="my-5 pt-5 text-muted text-center text-small">
        <p>
                        Already registered?<br />
                        <span className="line">
                            <Link to="/">Sign In</Link>
                        </span>
                    </p>
        </footer>
      </div>
    </>
  );
};

export default ResendVerificationLink;
