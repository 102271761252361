import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import axiosClient from "../../../../api/axios";
import { useState } from "react";
const Navbar = () =>{
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const [loadingStatus, setStatusLoading] = useState(false);


  const logout = async () => {
     // Update the position status in the frontend, if necessary
     localStorage.removeItem('token');
     localStorage.removeItem('type');
     // axios to /logout endpoint 
     setAuth({});
       setStatusLoading(false);
       navigate('/');

  };
    return <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical">
    <div className="container-fluid">
      {/* Toggler */}
      <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      {/* Brand */}
      <a className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="#">
        <img src="../logo.jpg" alt="..." />
      </a>
      {/* User menu (mobile) */}
      <div className="navbar-user d-lg-none">
        {/* Dropdown */}
        <div className="dropdown">
          {/* Toggle */}
          <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div className="avatar-parent-child">
              <img alt="Image Placeholder" src="../logo.jpg" className="avatar avatar- rounded-circle" />
              <span className="avatar-child avatar-badge bg-success" />
            </div>
          </a>
          {/* Menu */}
        </div>
      </div>
      {/* Collapse */}
      <div className="collapse navbar-collapse" id="sidebarCollapse">
        {/* Navigation */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/admin" className="nav-link">
              <i className="bi bi-house" /> Dashboard
              </NavLink>
          </li>
          <li className="nav-item">
          <NavLink to="/department" className="nav-link">
              <i className="bi bi-bar-chart" /> Departments
              </NavLink>
          </li>
          <li className="nav-item">
          <NavLink to="/election" className="nav-link">
              <i className="bi bi-chat" /> Election Types
              <span className="badge bg-soft-primary text-primary rounded-pill d-inline-flex align-items-center ms-auto">6</span>
              </NavLink>
          </li>
          {/* election */}
          <li className="nav-item">
          <NavLink to="/position" className="nav-link">
              <i className="bi bi-bar-chart" /> Positions
         </NavLink>
          </li>
          <li className="nav-item">
          <NavLink to="/admincandidates" className="nav-link">
              <i className="bi bi-bookmarks" /> Candidates
              </NavLink>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="bi bi-people" /> Result
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" onClick={logout}>
              <i className="bi bi-people" /> Logout
            </a>
          </li>
        </ul>
        {/* Divider */}
        <hr className="navbar-divider my-5 opacity-20" />
        
      </div>
    </div>
  </nav>
}
export default Navbar