import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../../context/AuthProvider";

const CandidatesDashboard = () => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    const logout = async () => {
        // if used in more components, this should be in context 
         // Remove token and type from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('type');
        // axios to /logout endpoint 
        setAuth({});
        navigate('/linkpage');
    }

    return (
        <section>
            <h1>Candidates Dashboards Page</h1>
            <br />
            <p>You must have been assigned an Candidates Dashboard role.</p>
            <div className="flexGrow">
                <Link to="/">Home</Link>
            </div>
            <div className="flexGrow">
                <button onClick={logout}>Sign Out</button>
            </div>
        </section>
    )
}

export default CandidatesDashboard