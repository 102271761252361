import { Spinner } from 'react-bootstrap';
const LoaderOverlay = () => {
    return (
      <div className="loader-overlay">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  };

  export default LoaderOverlay;