import axios from "axios";
import { API_URL } from "./axios";

export const axiosverify = axios.create({
    baseURL: `${API_URL}/api`,// replace with your API base URL
    timeout: 10000,
  });
  
  axiosverify.interceptors.request.use((config) => {
    const token = localStorage.getItem("Verifytoken")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  });

  axiosverify.interceptors.response.use((response) => {
    return response
  }, (error) => {
    const {response} = error;
  if (response.status === 401 || response.status === 402) {
    localStorage.removeItem('Verifytoken')
    // window.location.href = '/';
  } else if (response.status === 404) {
    //Show not found
    localStorage.removeItem('Verifytoken')
    window.location.href = '/';
  }else if(response.status === 403){
    localStorage.removeItem('Verifytoken')
    window.location.href = '/';
  }
  throw error;
  })