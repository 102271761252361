import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        // Retrieve token and type from localStorage on initial load
        const storedToken = localStorage.getItem('token');
        const storedType = localStorage.getItem('type');
    
        return {
          token: storedToken,
          type: storedType ? parseInt(storedType, 10) : null, // Assuming type is an integer
        };
    });

    // Function to update auth state
    const updateAuth = (newAuth) => {
        setAuth(newAuth);
    };
    
    return (
        <AuthContext.Provider value={{ auth, setAuth: updateAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
