
import './App.css';
import Layout from './component/Layout/Layout';
import Login from './component/authenications/Login';
import Register from './component/authenications/Register';
import RequireAuth from './utils/RequireAuth';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Unauthorized from './utils/Unauthorized';
import VotersDashboard from './component/Authenicated/Voters/VoterDashboard';
import CandidatesDashboard from './component/Authenicated/Candidate/CandidateDashboard';
import AdminDashoard from './component/Authenicated/Admin/AdminDashboard';
import Missing from './component/unAuthenicated/Missing';
import DisplayCandidates from './component/Authenicated/Voters/DisplayCandidates';
import AdminLogin from './component/authenications/adminLogin';
import Position from './component/Authenicated/Admin/Position/position';
import Department from './component/Authenicated/Admin/Department/department';
import ElectionType from './component/Authenicated/Admin/Elections/Election';
import Candidates from './component/Authenicated/Admin/Candidates/candidate';
import { useEffect, useState } from 'react';
import DisplayPositions from './component/Authenicated/Voters/displayPositions';
import useAuth from './hooks/useAuth';
import axiosClient from './api/axios';
import ResultView from './component/Authenicated/Voters/resultView';
import ResendVerificationLink from './component/authenications/resendVerificationLink';


function App() {
  const ROLES = {
    'Voter': 1,
    'Candidate': 2,
    'Admin': 3
  }
    const { auth } = useAuth();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

  const checkUser = async () => {
    setLoading(true);
    if (auth.token && auth.type !== 3) {
      try {
      await axiosClient
        .get("/user")
        .then(({ data }) => {
        })
        .catch((error) => {
          // Handle error if necessary
          if (error.response) {
          }
          if (error.response && error.response.status === 401) {
            // Unauthorized, redirect to login page
            navigate('/', { replace: true });
          }
          setLoading(false);
        });
      } catch (error) {
        // Handle error if necessary
        if (error.response && error.response.status === 401) {
          // Unauthorized, redirect to login page
          navigate('/', { replace: true });
        }
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  
  useEffect(() => {
    const fetchUserData = async () => {
      await checkUser();
      if (!auth.token) {
        // Redirect to the login page if not authenticated
        navigate('/', { replace: true });
      } else if (auth.type === 1) {
        navigate('/voters', { replace: true });
      } else if (auth.type === 2) {
        navigate('/candidate', { replace: true });
      } else if (auth.type === 3) {
        navigate('/admin', { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    };
  
    fetchUserData();
  }, [auth.token]);
 
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        {/* <Route path="/" element={<LinkPage />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="adminLogin" element={<AdminLogin />} />
        <Route path="register" element={<Register />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="/verifyEmail" element={<ResendVerificationLink />} />
        
       
        {/* <Route path="linkpage" element={<LinkPage />} /> */}

         {/* we want to protect these routes */}
        {/* <Route element={<PersistLogin />}> */}
        <Route element={<RequireAuth allowedRoles={[ROLES.Voter]} />}>
          <Route path="/voters" element={<VotersDashboard/>} />
          <Route path="/positions" element={<DisplayPositions/>} />
          <Route path="/displayCandidates" element={<DisplayCandidates/>} />
          <Route path="/result" element={<ResultView/>} />
          
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Candidate]} />}>
          <Route path="/candidate" element={<CandidatesDashboard />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="/admin" element={<AdminDashoard />} />
          <Route path="/position" element={<Position />} />
          <Route path="/department" element={<Department />} />
          <Route path="/election" element={<ElectionType />} />
          <Route path="/admincandidates" element={<Candidates />} />

          
        </Route>
        {/* </Route> */}

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
