import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navbar from "./layout/navbar";
import axiosClient from '../../../api/axios';

const AdminDashoard = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);


  const fetchData = async () => {
    // Fetch data logic, replace with your actual fetch logic
    try {
      setLoading(true);
      await axiosClient
        .get("/election/results")
        .then((res) => {
          setRecords(res.data.candidates);
          setLoading(false);
        
        })
        .catch((error) => 
        {

        }
        );
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  // 
    return (
        <Container fluid>
 <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
    {/* Vertical Navbar */}
    <Navbar/>
    {/* Main content */}
    <div className="h-screen flex-grow-1 overflow-y-lg-auto">
      {/* Header */}
      <header className="bg-surface-primary border-bottom pt-6">
        <div className="container-fluid">
          <div className="mb-npx">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                {/* Title */}
                <h1 className="h2 mb-0 ls-tight">Election Management System</h1>
              </div>
              {/* Actions */}
              <div className="col-sm-6 col-12 text-sm-end">
                <div className="mx-n1">
                  <a href="#" className="btn d-inline-flex btn-sm btn-neutral border-base mx-1">
                    <span className=" pe-2">
                      <i className="bi bi-pencil" />
                    </span>
                    <span>Edit</span>
                  </a>
                  <a href="#" className="btn d-inline-flex btn-sm btn-primary mx-1">
                    <span className=" pe-2">
                      <i className="bi bi-plus" />
                    </span>
                    <span>Create</span>
                  </a>
                </div>
              </div>
            </div>
            {/* Nav */}
            <ul className="nav nav-tabs mt-4 overflow-x border-0">
              <li className="nav-item ">
                <a href="#" className="nav-link active">All files</a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {/* Main */}
      <main className="py-6 bg-surface-secondary">
        <div className="container-fluid">
          {/* Card stats */}
          <div className="row g-6 mb-6">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card shadow border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <span className="h6 font-semibold text-muted text-sm d-block mb-2">Positions</span>
                      {/* <span className="h3 font-bold mb-0">$750.90</span> */}
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                        <i className="bi bi-credit-card" />
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-2 mb-0 text-sm">
                    <span className="badge badge-pill bg-soft-success text-success me-2">
                      <i className="bi bi-arrow-up me-1" />13%
                    </span>
                    <span className="text-nowrap text-xs text-muted">Since last month</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card shadow border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <span className="h6 font-semibold text-muted text-sm d-block mb-2">Election Type</span>
                      {/* <span className="h3 font-bold mb-0">215</span> */}
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                        <i className="bi bi-people" />
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-2 mb-0 text-sm">
                    <span className="badge badge-pill bg-soft-success text-success me-2">
                      <i className="bi bi-arrow-up me-1" />30%
                    </span>
                    <span className="text-nowrap text-xs text-muted">Candidates</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card shadow border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <span className="h6 font-semibold text-muted text-sm d-block mb-2">Students</span>
                      {/* <span className="h3 font-bold mb-0">1.400</span> */}
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                        <i className="bi bi-clock-history" />
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-2 mb-0 text-sm">
                    <span className="badge badge-pill bg-soft-danger text-danger me-2">
                      <i className="bi bi-arrow-down me-1" />-5%
                    </span>
                    <span className="text-nowrap text-xs text-muted">Since last month</span>
                  </div> */}
                </div>
              </div>
            </div>
            
          </div>
          <div className="card shadow border-0 mb-7">
            <div className="card-header">
              <h5 className="mb-0">Election Result</h5>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Position</th>
                    <th scope="col">Score/Result</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                {records.map(candidate => (
                  <tr key={candidate.id}>
                    <td>{candidate.id}</td>
                    <td>{candidate.name}</td>
                    <td>{candidate.position.name}</td>
                    <td>{candidate.votes_count} Vote(s)</td>
                    {/* Add additional table cells for other candidate information */}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>

      </Container>
    )
}

export default AdminDashoard