import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import './nav.css';
const Navbars = () =>{
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const logout = async () => {
    // Remove token and type from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('type');
    // axios to /logout endpoint 
    setAuth({});
    navigate('/');
  };
    return <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical">
    <div className="container-fluid">
      {/* Toggler */}
      <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      {/* Brand */}
      <a className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="#">
    <img src="../logo.jpg" className="img-fluid thumbnail img-responsive d-block mx-auto" alt="..." />
</a>
      {/* User menu (mobile) */}
      {/* Collapse */}
      <div className="collapse navbar-collapse" id="sidebarCollapse">
        {/* Navigation */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/voters" className="nav-link">
              <i className="bi bi-house" /> Dashboard
              </NavLink>
          </li>
          <li className="nav-item">
          <NavLink to="/result" className="nav-link">
              <i className="bi bi-bar-chart" /> Election Result
              </NavLink>
          </li>
         
          <li className="nav-item">
            <a className="nav-link" onClick={logout}>
              <i className="bi bi-people" /> Logout
            </a>
          </li>
        </ul>
        {/* Divider */}
        <hr className="navbar-divider my-5 opacity-20" />
        
      </div>
    </div>
  </nav>
}
export default Navbars