import Navbars from "./Layout/Navbars";
import {
  Container,
  Spinner,
  Alert,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosClient from "../../../api/axios";
import CandidateList from "./displayLayout";
import { useNavigate } from "react-router-dom";

const DisplayCandidates = () => {
  const location = useLocation();
  // Assuming location.state is the object you provided
  const { record } = location.state;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    candidates: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axiosClient
          .get(`/getElectionCandidates/${record.id}/${record.category_id}`) // Replace with your API endpoint
          .then((response) => {
            setData(response.data);
            // checkVote();
          });
        setTimeout(() => {
          setLoading(false); // Set loading to false when data is available
        }, 1000);
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  const LoaderOverlay = () => {
    return (
      <div className="loader-overlay">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  };
  const navigate = useNavigate(); // Use the useNavigate hook

  const goBack = () => {
    navigate(-1); // Use the navigate function to navigate back
  };
  return (
    <Container fluid>
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        {/* Vertical Navbar */}
        <Navbars />
        {/* Main content */}
        <div className="h-screen flex-grow-1 overflow-y-lg-auto">
          {/* Header */}
          <header className="bg-surface-primary border-bottom pt-6">
            <div className="container-fluid">
              <div className="mb-npx">
                <div className="row align-items-center">
                  <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                    {/* Title */}
                    <h1 className="h2 mb-0 ls-tight">
                     Display Candidates
                    </h1>
                  </div>
                  {/* Actions */}
                </div>
                {/* Nav */}
              </div>
            </div>
          </header>
          {/* Main */}
          <main className="py-6 bg-surface-secondary">
            <div className="container-fluid">
              {/* Card stats */}
              <div className="row g-6 mb-6">
                {loading ? (
                  <LoaderOverlay />
                ) : data.candidates.length > 0 ? (
                  <CandidateList candidates={data.candidates}  position_id={record.id}/>
                ) : (
                  <>
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    <Alert variant="info">No candidate found.</Alert>
                  </div>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                    <button className="btn btn-danger" onClick={goBack}>
                      Go Back
                    </button>
                  </div>
                  </>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Container>
  );
};
export default DisplayCandidates;
