import { useState } from "react";
import axiosClient from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import LoaderOverlay from "../../utils/loader";

const LOGIN_URL = "/login/user";

const Login = () => {
  const { setAuth } = useAuth();
  const [userData, setUserData] = useState({
    voter_number: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const from =
    location.state && location.state.from ? location.state.from.pathname : "/";

  const [loading, setLoading] = useState(false);
  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
    // Validate the field and update the errors state
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";

    // Add your validation logic here
    switch (name) {
      case "name":
        error = value.trim() === "" ? "Name cannot be empty" : "";
        break;
      case "email":
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        error = !emailRegex.test(value) ? "Enter a valid email address" : "";
        break;
      case "password":
        // Use the provided regex for password validation
        const passwordRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
        error = !passwordRegex.test(value)
          ? "Password must meet the criteria: at least 1 lowercase, 1 uppercase, 1 digit, 1 special character, and be 8-24 characters long."
          : "";
        break;
      // Add validation for other fields as needed
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosClient.post(LOGIN_URL, userData);
      if(response.data.status === true){
        const res = response?.data;
        const token = res.token;
        const type = res.type;
        // Set token and type in localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("type", type);
        // Update the auth state
        setAuth({ token, type });
        setLoading(false);
        navigate(from, { replace: true });
      }else{
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.response.data.error, {
        position: "top-right",
        autoClose: 2000, // Auto close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoading(false);
    
      if(err.response.data.error === "Please Verify Email"){
        localStorage.setItem("Verifytoken", err.response.data.token);
        // Update the auth state
        setTimeout(() => {
          navigate('/verifyEmail', { replace: true });
      }, 1000);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="row m-5 no-gutters shadow-lg">
          <div className="col-md-6 d-none d-md-block">
            <img
              src="../bg.jpeg"
              className="img-fluid"
              style={{ minHeight: "100%" }}
            />
          </div>
          <div className="col-md-6 bg-white p-5">
            <h3 className="pb-3">Login</h3>
            <div className="form-style">
              <form  onSubmit={handleSubmit}>
                <div className="form-group pb-3">
                  <label htmlFor="name">Reg/Matric Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="voter_number"
                    aria-describedby="emailHelp"
                    defaultValue
                    autoComplete="off"
                    value={userData.voter_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group pb-3">
                  <label htmlFor="Password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="exampleInputPassword1"
                    value={userData.password}
                    onChange={handleChange}
                    required
                  />
                  <div className="invalid-feedback">
                    {errors.password && <span>{errors.password}</span>}
                  </div>
                  {errors.name && <span>{errors.name}</span>}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <input name type="checkbox" defaultValue />{" "}
                    <span className="pl-2 font-weight-bold">Remember Me</span>
                  </div>
                  <div>
                    <a href="#">Forget Password?</a>
                  </div>
                </div>
                <div className="pb-2">
                  {loading ? (
                    <LoaderOverlay />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-dark w-100 font-weight-bold mt-2"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
              <div>
                <div class="pt-4 text-center">
                Not Registered?{" "} <Link to="/register">Create an Account</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 text-center">
          <Link to="/adminLogin">Staff</Link>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Login;
