import { useState } from "react";
import axiosClient from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import {  useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./adminLogin.css";
import LoaderOverlay from "../../utils/loader";

const LOGIN_URL = "/login/admin";

const AdminLogin = () => {
  const { setAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });


  const navigate = useNavigate();
  const location = useLocation();
  const from =
    location.state && location.state.from ? location.state.from.pathname : "/";
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axiosClient.post(LOGIN_URL, userData);
      const res = response?.data;
      const token = res.token;
      const type = res.type;
      // Set token and type in localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("type", type);
      // Update the auth state
      setAuth({ token, type });
      navigate(from, { replace: true });
      setLoading(false);
    } catch (err) {
      toast.error(err.response.statusText, {
        position: "top-right",
        autoClose: 3000, // Auto close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <h2 className="text-center text-dark mt-5">
              Login To Cast your Vote
            </h2>
            {/* <div className="text-center mb-5 text-dark">
              Made with bootstrap
            </div> */}
            <div className="card my-5">
              <form
                className="card-body cardbody-color p-lg-5"
                onSubmit={handleSubmit}
              >
                <div className="text-center">
                  <img
                    src="../logo.jpg"
                    className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                    width="200px"
                    alt="profile"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder
                    defaultValue
                    autoComplete="off"
                    value={userData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="text-center">
                  {loading ? (
                    <LoaderOverlay />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary px-5 mb-5 w-100"
                    >
                      Login
                    </button>
                  )}
                </div>
                <div
                  id="emailHelp"
                  className="form-text text-center mb-5 text-dark"
                ></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
