// // axiosConfig.js
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// // export const API_URL = "https://nauth-unizik.ng/elect";
// export const API_URL = "http://127.0.0.1:8000";
// const axiosClient = axios.create({
//   baseURL: `${API_URL}/api`,
//   timeout: 10000,
// });

// axiosClient.interceptors.request.use((config) => {
//   const token = localStorage.getItem('token');
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

// axiosClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { response } = error;
//     const navigate = useNavigate(); // Access navigate function using useNavigate hook

//     if (response && (response.status === 401 || response.status === 402)) {
//       localStorage.removeItem('token');
//       localStorage.removeItem('type');
//       navigate('/'); // Redirect to login page
//     } else if (response && response.status === 404) {
//       // Handle 404 error gracefully, e.g., show a not found page
//       navigate('/');
//     }

//     return Promise.reject(error); // Reject the promise to propagate the error
//   }
// );

// export default axiosClient;


// axiosConfig.js
import axios from 'axios';

export const API_URL = "https://nauth-unizik.ng/elect";
// export const API_URL = "http://127.0.0.1:8000";

const axiosClient = axios.create({
  baseURL: `${API_URL}/api`,// replace with your API base URL
  timeout: 10000, // set your desired timeout
  // other axios configurations can be added here
});

axiosClient.interceptors.request.use((config) => {
   const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
})
axiosClient.interceptors.response.use((response) => {
  if (response.data.message === "Unauthenticated.") {
    localStorage.removeItem('token')
    window.location.href = '/';
  } 
  // else if(response.data.message === "Your email address is not verified."){
  //   window.location.href = '/';
  // }
  // 
  return response
}, (error) => {
  const {response} = error;
  if (response.status === 401 || response.status === 402) {
    localStorage.removeItem('token')
    // window.location.href = '/';
  } else if (response.status === 404) {
    //Show not found
    localStorage.removeItem('token')
    window.location.href = '/';
  }else if(response.status === 403){
    localStorage.removeItem('token')
    window.location.href = '/';
  }
  throw error;
})


export default axiosClient;

