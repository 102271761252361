import React from 'react';
import Navbars from './Layout/Navbars';
import { useEffect, useState } from "react";
import axiosClient from "../../../api/axios";
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';

const ResultView = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);


  const fetchData = async () => {
    // Fetch data logic, replace with your actual fetch logic
    try {
      setLoading(true);
      await axiosClient
        .get("/studentVote/results")
        .then((res) => {
          setRecords(res.data.candidates);
          setLoading(false);
        
        })
        .catch((error) => 
        {

        }
        );
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  // 
    return (
        <Container fluid>
 <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
    {/* Vertical Navbar */}
    <Navbars/>
    {/* Main content */}
    <div className="h-screen flex-grow-1 overflow-y-lg-auto">
      {/* Header */}
      <header className="bg-surface-primary border-bottom pt-6">
        <div className="container-fluid">
          <div className="mb-npx">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                {/* Title */}
                <h1 className="h2 mb-0 ls-tight">Election Management System Result</h1>
              </div>
            </div>
            {/* Nav */}
            
          </div>
        </div>
      </header>
      {/* Main */}
      <main className="py-6 bg-surface-secondary">
        <div className="container-fluid">
          <div className="card shadow border-0 mb-7">
            <div className="card-header">
              <h5 className="mb-0">Election Result</h5>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Position</th>
                    <th scope="col">Score/Result</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                {records.map(candidate => (
                  <tr key={candidate.id}>
                    <td>{candidate.id}</td>
                    <td>{candidate.name}</td>
                    <td>{candidate.position.name}</td>
                    <td>{candidate.votes_count} Vote(s)</td>
                    {/* Add additional table cells for other candidate information */}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>

      </Container>
    )
}

export default ResultView