import React, { useEffect,useState, useRef } from 'react';
import axiosClient from '../../../../api/axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const AddCandidatesModal = ({ showModal, closeModal,fetchData}) => {

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [userExists, setUserExists] = useState("");
    const [authError, setAuthError] = useState({});
  
    const [validationResult, setValidationResult] = useState(false);
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        reg_number: "",
        category_id: "",
        department_id: "",
        position_id:"",
        image: null,
      });
      const handleChange = async (e) => {
        const { name, value, files } = e.target;
        // Update the user data state
         setUserData((prevData) => ({ ...prevData, [name]: value }));
        // If the input is for file, update the image property
        if (name === 'image') {
          setUserData((prevData) => ({ ...prevData, [name]: files[0] }));
        } else {
          // For other inputs, update the user data state
          setUserData((prevData) => ({ ...prevData, [name]: value }));
        }

        // If the selected department is '0' (SUG/Others), set a default position ID or clear the position ID
        if (name === 'department_id' && value === '0') {
          setUserData((prevData) => ({ ...prevData, position_id: '' }));
        }
      };
      // const handleChange = async (e) => {
      //   const { name, value } = e.target;
      //   setUserData((prevData) => ({ ...prevData, [name]: value }));  
      //   // if (name === 'reg_number') {
      //   //     try {
      //   //       // Make an API request to check if the registration number already exists
      //   //       const response = await axiosClient.get(`/checkMatric/${value.trim()}`);
        
      //   //       if (response.status === 201 && response.data.voter_number && response.data.status) {
      //   //         setValidationResult(true);
      //   //         setUserExists("");
      //   //       } else {
      //   //         setValidationResult(false);
      //   //       }
      //   //     } catch (error) {
      //   //       const response = error.response;
      //   //       if (response && response.status === 403) {
      //   //         setValidationResult(false);
      //   //         setUserExists(response.data.error);
      //   //         setAuthError(response.data.data);
      //   //       }
      //   //     }
      //   //   }
      // }

      const [data, setData] = useState({
        department: [],
      });
      const [positions, setCategPosition] = useState({
        positions: [],
      });
    
      const [categories, setCategory] = useState({
        categories: [],
      });
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axiosClient.get("/department"); // Replace with your API endpoint

            setData(response.data);
          } catch (error) {
          }
        };
        const fetchDataCategoryPosition = async () => {
            try {
              const response = await axiosClient.get("/positions/indexAdmin"); // Replace with your API endpoint
          
              setCategPosition(response.data);
            } catch (error) {

            }
          };
          const fetchCategory = async () => {
            try {
              const response = await axiosClient.get("/categories"); // Replace with your API endpoint
              setCategory(response.data);
            } catch (error) {

            }
          };
        fetchDataCategoryPosition();
        fetchData();
        fetchCategory()
      }, []);


    const handleSave  = async (e) => {
      e.preventDefault();
      // Create FormData object to send both text and file data
    const formData = new FormData();
    formData.append('name', userData.name);
    formData.append('email', userData.email);
    formData.append('reg_number', userData.reg_number);
    formData.append('category_id', userData.category_id);
    formData.append('department_id', userData.department_id);
    formData.append('position_id', userData.position_id);
    formData.append('image', userData.image);

        try {
          setLoading(true);
          const response = await axiosClient.post('/candidates/save', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }});
          if (response.status === 201) {
            toast.success('Department data successfully saved');
            closeModal();
            fetchData();
          } else {
            toast.error(response.statusText);

            closeModal();
          }
        } catch (error) {
          toast.error(error.response.data.message);
          closeModal();
        } finally {
          setLoading(false);
        }
      };
    // useEffect(() => {handleSave() }, [])
    return (
      <div className={`modal ${showModal ? 'd-block' : ''}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Candidates</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body border border-primary p-4">
              {/* Add your form fields for department data */}
                <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                  className="form-control  border border-primary"
                />
                <div className="col-md-12 mb-3 mt-3">
                  <label htmlFor="department_id">Department  <small>(Note: You can select SUG/Others(General Election) for General Election ) </small></label>
                  <select
                    className="form-control border border-primary"
                    name="department_id"
                    value={userData.department_id}
                    onChange={handleChange}
                  >
                    <option value="">Select an Department</option>
                    {data.department.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                     <option value="0">SUG/Others(General Election)</option>
                  </select>
                </div>
                <div className="col-md-12 mb-3 mt-3">
                  <label htmlFor="position_id">Election Position</label>
                  <select
                    className="form-control border border-primary"
                    name="position_id"
                    value={userData.position_id}
                     onChange={handleChange}
                  >
                    <option value="">Select an Election Position</option>
                    {positions && positions.positions && positions.positions
                      // Filter positions based on the selected department ID
                      .filter(position => position.department_id === userData.department_id)
                      // Map filtered positions to options
                      .map(position => (
                        <option key={position.id} value={position.id}>
                          {position.name}
                        </option>
                      ))}
                  </select>

                </div>
                <div className="col-md-12 mb-3 mt-3">
                  <label htmlFor="category_id">Election Type</label>
                  <select
                    className="form-control border border-primary"
                    name="category_id"
                    value={userData.category_id}
                    onChange={handleChange}
                  >
                    <option value="">Select an Election Type</option>
                    {categories && categories.categories && categories.categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Reg/Matric Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="reg_number"
                    placeholder
                    defaultValue
                    autoComplete="off"
                    value={userData.reg_number}
                    onChange={handleChange}
                    required
                  />
                  <span style={{ color: 'red' }}>{userExists}</span>
                </div>
                <div className="row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="email">
                    Email <span className="text-muted">(Compulsory)</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    placeholder="you@example.com"
                    required
                  />
                  <div className="invalid-feedback">
                    {errors.email && <span>{errors.email}</span>}
                  </div>
                </div>
                <input type="file" name="image" onChange={handleChange} />
              </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-primary" onClick={handleSave} disabled={loading || !validationResult} > */}
              <button type="button" className="btn btn-primary" onClick={handleSave} disabled={loading} >
              {loading ? 'Saving...' : 'Save'}
              </button>
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>

      </div>
    );
  };
  
  export default AddCandidatesModal