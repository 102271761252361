import React, { useEffect,useState, useRef } from 'react';
import axiosClient from '../../../../api/axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const AddElectionModal = ({ showModal, closeModal,fetchData}) => {

    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({
        name: "",
        isGeneral: "",
        start_time: "",
        end_time: "",
        department_id: "",
      }); 
      const handleChange = async (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));  
      }

      const [data, setData] = useState({
        department: [],
      });
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axiosClient.get("/department"); // Replace with your API endpoint
            setData(response.data);
          } catch (error) {
          }
        };
    
        fetchData();
      }, []);

      const options = [
        { value: 1, label: 'General Election' },
        { value: 0, label: 'Departmental Election' },
      ];




    const handleSave  = async (e) => {
      e.preventDefault();
        try {
          setLoading(true);
          const response = await axiosClient.post('/categories', userData);
          if (response.status === 201) {
            toast.success('Department data successfully saved');
            closeModal();
            fetchData();
          } else {
            toast.error(response.statusText);

            closeModal();
          }
        } catch (error) {
          toast.error(error.response.data.message);
          closeModal();
        } finally {
          setLoading(false);
        }
      };
    // useEffect(() => {handleSave() }, [])
    return (
      <div className={`modal ${showModal ? 'd-block' : ''}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Department</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body border border-primary p-4">
              {/* Add your form fields for department data */}
                <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                  className="form-control  border border-primary"
                />
                <div className="col-md-12 mb-3 mt-3">
                  <label htmlFor="department_id">Department</label>
                  <select
                    className="form-control border border-primary"
                    name="department_id"
                    value={userData.department_id}
                    onChange={handleChange}
                  >
                    <option value="">Select an Department</option>
                    {data.department.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                     <option value="0">SUG/Others(General Election)</option>
                  </select>
                </div>
                <label htmlFor="electionType">Select Election Type:</label>
                <div className="col-md-12 mb-3">
                <select
                    id="electionType"
                    name='isGeneral'
                    className="form-select"
                    value={userData.isGeneral}
                    onChange={handleChange}
                >
                    <option value="" disabled>Select...</option>
                    <option value="1">General Election</option>
                    <option value="0">Departmental Election</option>
                </select>
                </div>
                <div className="col-md-12 mb-3">
                <label htmlFor="Password">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="start_time"
                    value={userData.start_time}
                    onChange={handleChange}
                    required
                  />
                  </div>
                  <div className="col-md-12 mb-3">
                  <label htmlFor="Password">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="end_time"
                    value={userData.end_time}
                    onChange={handleChange}
                    required
                  />
                  </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleSave} disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
              </button>
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>

      </div>
    );
  };
  
  export default AddElectionModal