import React, { useEffect,useState, useRef } from 'react';
import axiosClient from '../../../../api/axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const AddPositionModal = ({ showModal, closeModal,fetchData}) => {

    const [loading, setLoading] = useState(false);

    const [userData, setUserData] = useState({
        name: "",
        category_id: "",
        department_id: "",
      }); 
      const handleChange = async (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));  
      }

      const [data, setData] = useState({
        categories: [],
      });
      const [dataDept, setDataDept] = useState({
        department: [],
      });
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axiosClient.get("/categories"); // Replace with your API endpoint
            setData(response.data);
          } catch (error) {
          }
        };
        const fetchDataDept = async () => {
          try {
            const response = await axiosClient.get("/department"); // Replace with your API endpoint
            setDataDept(response.data);
          } catch (error) {
          }
        };
        fetchDataDept();
        fetchData();
      }, []);




    const handleSave  = async (e) => {
      e.preventDefault();
        try {
          setLoading(true);
        
          const response = await axiosClient.post('/positions', userData);
   
          if (response.status === 201) {
            toast.success('Positions data successfully saved');
            closeModal();
            fetchData();
          } else {
            toast.error(response.statusText);
            closeModal();
          }
        } catch (error) {
          toast.error(error.response.data.message);
          closeModal();
        } finally {
          setLoading(false);
        }
      };
    // useEffect(() => {handleSave() }, [])
    return (
      <div className={`modal ${showModal ? 'd-block' : ''}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Positions</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body border border-primary p-4">
              {/* Add your form fields for department data */}
                <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                  className="form-control  border border-primary"
                />
                <div className="col-md-12 mb-3 mt-3">
                  <label htmlFor="category_id">Election Type</label>
                  <select
                    className="form-control border border-primary"
                    name="category_id"
                    value={userData.category_id}
                    onChange={handleChange}
                  >
                    <option value="">Select an Election Type</option>
                    {data.categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="col-md-12 mb-3 mt-3">
                  <label htmlFor="category_id">Department</label>
                  <select
                    className="form-control border border-primary"
                    name="department_id"
                    value={userData.department_id}
                    onChange={handleChange}
                  >
                    <option value="">Select an Department</option>
                    {dataDept.department.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                     <option value="0">SUG/Others(General Election)</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleSave} disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
              </button>
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>

      </div>
    );
  };
  
  export default AddPositionModal