import React, { useEffect,useState, useRef } from 'react';
import axiosClient from '../../../../api/axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const AddDepartmentModal = ({ showModal, closeModal,fetchData}) => {

    const [loading, setLoading] = useState(false);
    const [nameRef, setnameRef] = useState("");

  
    const handleSave  = async (e) => {
      e.preventDefault();
      const payload = {
        name:nameRef,
      }
        try {
          setLoading(true);
    
          const response = await axiosClient.post('/departments/save', payload);
          if (response.status === 201) {
            toast.success('Department data successfully saved');
            closeModal();
            fetchData();
          } else {
            toast.error(response.statusText);
            
            closeModal();
          }
        } catch (error) {
          toast.error(error.response.data.message);
          
          closeModal();
        } finally {
          setLoading(false);
        }
        setnameRef("")
      };
    // useEffect(() => {handleSave() }, [])
    return (
      <div className={`modal ${showModal ? 'd-block' : ''}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Department</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body border border-primary p-4">
              {/* Add your form fields for department data */}
                <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={nameRef}
                  onChange={(e) => setnameRef(e.target.value)}
                  className="form-control  border border-primary"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleSave} disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
              </button>
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>

      </div>
    );
  };
  
  export default AddDepartmentModal