import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosClient from "../../../api/axios";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import Navbars from "./Layout/Navbars";

const DisplayPositions = () => {
  const location = useLocation();
  // Assuming location.state is the object you provided
  const { record } = location.state;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    positions: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        const response = await axiosClient.get(`/getPosition/${record.id}`); // Replace with your API endpoint
          setData(response.data);
          setTimeout(() => {
            setLoading(false); // Set loading to false when data is available
          }, 1000);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const navigateToEdit = (record) => {
    navigate("/displayCandidates", { state: { record: record } });
  };
  const LoaderOverlay = () => {
    return (
      <div className="loader-overlay">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  };

  const goBack = () => {
    navigate(-1); // Use the navigate function to navigate back
  };
  return (
    <Container fluid>
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        {/* Vertical Navbar */}
        <Navbars />
        {/* Main content */}
        <div className="h-screen flex-grow-1 overflow-y-lg-auto">
          {/* Header */}
          <header className="bg-surface-primary border-bottom pt-6">
            <div className="container-fluid">
              <div className="mb-npx">
                <div className="row align-items-center">
                  <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                    {/* Title */}
                    <h1 className="h2 mb-0 ls-tight">Contestant Positions</h1>
                  </div>
                  {/* Actions */}
                </div>
                {/* Nav */}
              </div>
            </div>
          </header>
          {/* Main */}
          <main className="py-6 bg-surface-secondary">
            <div className="container-fluid">
              {/* Card stats */}
              <div className="row g-6 mb-6">
                {loading ? (
                  <LoaderOverlay />
                ) : (
                  <>
                    <Row>
                      {data.positions.length > 0 ? (
                        data.positions.map((position) => (
                          <Col
                            key={position.id}
                            md={4}
                            className="d-flex justify-content-center align-items-center mt-5"
                          >
                            <Card style={{ width: "20rem", margin: "10px" }}>
                              <Card.Body>
                                <Card.Title>{position.name}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                  Department:{" "}
                                  {position.department
                                    ? position.department.name
                                    : "All Students"}
                                </Card.Subtitle>
                                <Button
                                  onClick={(e) => navigateToEdit(position)}
                                  variant="primary"
                                >
                                  View Details
                                </Button>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center align-items-center mt-2 mb-5">
                          <Alert variant="info">No position found.</Alert>
                        </div>
                      )}
                    </Row>

                    <br />
                    <div className="d-flex justify-content-center align-items-center mt-2 mb-5">
                      <button className="btn btn-danger mx-5" onClick={goBack}>
                        Go Back
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Container>
  );
};
export default DisplayPositions;
