import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import axiosClient from "../../api/axios";

const Layout = () => {
    const navigate = useNavigate();
    const { auth, loading: authLoading, error: authError } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUser = async () => {
            if (auth && auth.token && auth.type !== 3) {
                try {
                    await axiosClient.get("/user");
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        navigate('/', { replace: true });
                    }
                }
            } else if (!auth || !auth.token) {
                navigate('/', { replace: true });
            } else {
                if (auth.type === 1) {
                    navigate('/voters', { replace: true });
                } else if (auth.type === 2) {
                    navigate('/candidate', { replace: true });
                } else if (auth.type === 3) {
                    navigate('/admin', { replace: true });
                } else {
                    navigate('/', { replace: true });
                }
            }
            setLoading(false);
        };

        if (!authLoading) {
            checkUser();
        }
    }, [auth, authLoading]); // Include auth, authLoading, and navigate in the dependency array

    useEffect(() => {
        if (authError) {
            navigate('/', { replace: true });
        }
    }, [authError, navigate]); // Include authError and navigate in the dependency array

    return (
        <main className="App">
            {loading || authLoading ? (
                <p>Loading...</p>
            ) : (
                <Outlet />
            )}
        </main>
    );
};

export default Layout;
