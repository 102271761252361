import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
   
    const isAuthorized = auth?.type && allowedRoles?.includes(auth.type);
return (
  isAuthorized
    ? <Outlet />
    : auth?.user
    ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    : <Navigate to="/" state={{ from: location }} replace />
);
}

export default RequireAuth;
